import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function IonixxTechnologies() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c1_7945260510.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Ionixx Technologies
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Ionixx Technologies have been in the design industry for about
                seven years now.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 50-249
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.ionixxtech.com/" target="_blank">
                  https://www.ionixxtech.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | No. L 25 (Second Floor), Dr. Vikram Sarabhai
                  Instronics Estate, SRP Tools Thiruvanmiyur, Dr. Vasi Estate,
                  Phase II, Tharamani, Chennai, Tamil Nadu 600041
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Ionixx Technologies have been in the design industry for about
                seven years now. The collective experience of the team at Ionixx
                Technologies is over 300 years. The design company provides end
                to end digital solutions for both start-ups and big players.
                Headquartered in Los Angeles, the company also operates in
                Chennai. Their tech team is proficient in DevOps and Blockchain
                and can develop custom enterprise apps that are unique and
                cloud-enabled to help your company take a step further in the
                digital world. Their roster of clients includes bigwigs like
                Chola, Shikhar Microfinance, Bitsian, Dubuqu, Unum, Bhooya! and
                Voyaj. They were awarded the Top Developers Clutch India 2019
                and Goodfirms Top Software Development Company.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
